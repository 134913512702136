import { DateTime } from "@bps/utils";
import { ChangeLogDto, PagingOptions } from "@libs/api/dtos/index.ts";
import { RefDataDto } from "@libs/api/ref-data/dto.ts";
import { ClaimAdjustmentDocumentTypes } from "@libs/gateways/acc/AccGateway.dtos.ts";

export const RFV_CD_TYPE_CODE_OTHER = "0.Other";
export const RFV_CD_TYPE_CODE_OTHER_TEXT = "Other";

export enum EncounterStatus {
  Open = "OPN",
  Committed = "COM",
  Closed = "CLS"
}

export enum EncounterTimerStatus {
  Running = "RUN",
  Paused = "PSE",
  Stopped = "STP"
}

export enum EncounterType {
  Consultation = "CON",
  VideoConsultation = "VCON",
  PhoneConsultation = "PCON",
  RecordUpdate = "RU"
}

export const ConsultEncounterTypes = [
  EncounterType.Consultation,
  EncounterType.PhoneConsultation,
  EncounterType.VideoConsultation
];

export enum EncounterLocation {
  Practice = "PRACTICE",
  Home = "HOME",
  Hospital = "HOSPITAL",
  Gym = "GYM",
  RACF = "RACF",
  Hostel = "HOSTEL",
  Hospice = "HOSPICE"
}

export enum ClinicalHeaderTableTitle {
  Tasks = "Tasks",
  Reactions = "Reactions",
  Notices = "Notices",
  Activities = "Activities"
}

export enum ClinicalDataTypeLabel {
  K10 = "K10",
  NPRS = "NPRS",
  OREBRO = "OREBRO",
  GEN = "General",
  Alcohol = "Alcohol",
  Tobacco = "Smoking",
  SubstanceUse = "Substance use",
  Injury = "ACC45",
  Adjustment = "ACC32",
  Review = "Claim review",
  Social = "Social",
  PhysicalActivity = "Physical activity",
  Eye = "Eye",
  TodaysNotes = "Today's notes",
  PatientSummary = "Patient summary",
  FullBody = "Full body",
  PSFS = "PSFS",
  GRCS = "GRCS",
  DASH = "DASH",
  DASH11 = "DASH 11",
  WorkHistory = "Work history",
  ClinicalTask = "Tasks",
  SOTAP = "SOTAP MSK",
  Discharge = "Discharge",
  Sleep = "Sleep",
  GeneralExamination = "General",
  BodyExam = "Body",
  Treatment = "Treatment",
  HistoryPresentingComplaint = "Hx of PC",
  ClinicalFlags = "Clinical Flags",
  Management = "Management",
  DermatomesAndMyotomes = "D/M/R",
  FamilyHistory = "Family history",
  SystemsReview = "Systems review",
  RAND36 = "RAND36",
  CentralNervousSystem = "CNS",
  Plan = "Plan",
  EPDS = "EPDS",
  DASS21 = "DASS 21",
  Observations = "Observations",
  Medications = "Medications",
  MedicationsAndSupplements = "Medications & supplements",
  ClinicalActivities = "Clinical notifications"
}

export enum IntakeFrequency {
  Daily = "DAY",
  Weekly = "WEEK",
  Monthly = "MNTH",
  Yearly = "YEAR"
}

export enum CorrespondencesTestElements {
  MoreButton = "correspondences-more-button",
  CreateButton = "correspondences-create-button",
  UploadButton = "correspondences-upload-button"
}

export enum MedicationsTestElements {
  PrescribeButton = "medications-prescribe-button",
  RecordButton = "medications-record-button",
  PrintButton = "medications-print-button",
  ContextualMenu = "medications-contextual-menu"
}

export enum ObservationsTestElements {
  AddButton = "observations-add-button"
}

export enum FamilySocialTestElements {
  FamilySocialSummary = "family-social-summary"
}

export enum MedicalHistoryTestElements {
  MoreButton = "medical-history-item-more",
  DateColumn = "medical-history-item-date",
  AddEntryButton = "medical-history-add-entry",
  NoHistoryButton = "medical-history-no-history",
  CertaintyColumn = "medical-history-item-certainty",
  DiagnosisColumn = "medical-history-item-diagnosis",
  StatusDropdown = "medical-history-status-dropdown",
  TypeDropdown = "medical-history-type-dropdown",
  NameFilter = "medical-history-name-filter",
  TableRow = "medical-history-table-row",
  Table = "medical-history-table"
}

export enum MedicationTestElements {
  ProductNameColumn = "medication-product-name",
  DoseColumn = "medication-dose",
  TableRow = "medication-table-row",
  Table = "medication-table"
}

export type IntakeStatus = string;
export type IntakeType = string;
export type IntakeUnit = string;

export enum MedicalCertainty {
  PatientReported = "PAR",
  Provisional = "PRV",
  Confirmed = "CFM"
}

export enum ReasonsForDiscard {
  EnteredInWrong = "EIR",
  WrongPatient = "WRP",
  Other = "OTH"
}

export enum ReasonsForPlanDiscard {
  EnteredInWrong = "EIR",
  Other = "OTH"
}

export enum ClinicalNotesSubHeadings {
  FullBody = "Full Body",
  FullBodyImages = "Full Body Images",
  Body = "Body exam",
  BodyExaminationImages = "Body Images",
  DMRImages = "D/M/R Images",
  BodyDiagramImages = "Body Diagram",
  PostureObservations = "Posture/observations",
  OtherMovementsJoints = "Other movements/joints",
  CNSImages = "CNS Images"
}

export enum ClinicalTaskDescription {
  ACC32 = "ACCNZ",
  RecordOutcomeMeasure = "RECNZ",
  FollowUpRequest = "FOLUR",
  ClaimReview = "CLANZ"
}

export enum ClinicalTaskPriority {
  High = "HIGH",
  Medium = "MED",
  Low = "LOW"
}

export enum ClinicalNoteSections {
  Main = "MAIN",
  Examinations = "EXAMINATIONS",
  ClinicalTools = "CLINICAL_TOOLS",
  MedicalHistory = "MEDICAL_HISTORY",
  Actions = "ACTIONS",
  ConditionDischarges = "CONDITION_DISCHARGES",
  Subjective = "SUBJECTIVE",
  Objective = "OBJECTIVE",
  Treatment = "TREATMENT",
  Analysis = "ANALYSIS",
  Plan = "PLAN",
  Assessment = "ASSESSMENT"
}

export enum SOTAPSectionText {
  Subjective = "Subjective",
  Objective = "Objective",
  TAP = "Treatment, Analysis & Plan"
}

export enum SOTAPSectionTextShort {
  Subjective = "Sub.",
  Objective = "Obj.",
  TAP = "TA & P"
}

export enum CessationAdvice {
  BriefSmokingCessAdvGiven = "BRFSMK",
  RefToSmokingCessSupport = "REFSMK",
  PrescribedSmokingCessMedication = "PRSSMK",
  SmokingCessBehaviourSupport = "SMKBHV",
  RefusedSmokingCessSupport = "RFSSMK",
  RiskManagement = "RSKMNG"
}

export enum Accommodation {
  Homeless = "6",
  Hostel = "4",
  NursingHome = "5",
  OtherPrivateHouse = "3",
  OwnHome = "1",
  RelativesHome = "2",
  RentalHome = "7"
}

export enum Relationship {
  Mother = "MOTHER",
  Father = "FATHER",
  Sister = "SIS",
  Brother = "BRO",
  Daughter = "DAUGH",
  Son = "SON",
  FraternalTwinSister = "FTSIS",
  IdenticalTwinSister = "ITSIS",
  FraternalTwinBrother = "FTBRO",
  IdenticalTwinBrother = "ITBRO",
  MaternalGrandmother = "MGM",
  PaternalGrandmother = "PGM",
  MaternalGrandfather = "MGF",
  PaternalGrandfather = "PGF",
  MaternalHalfSister = "MHSIS",
  PaternalHalfSister = "PHSIS",
  MaternalHalfBrother = "MHBRO",
  PaternalHalfBrother = "PHBRO",
  MaternalAunt = "MAUNT",
  PaternalAunt = "PAUNT",
  MaternalUncle = "MUNCLE",
  PaternalUncle = "PUNCLE",
  Niece = "NIECE",
  Nephew = "NEPHEW",
  MaternalCousin = "MCOUSIN",
  PaternalCousin = "PCOUSIN"
}

export enum LivesWith {
  Spouse = "1",
  Relative = "3",
  Friend = "4",
  Alone = "5",
  Partner = "2"
}

export enum SafetyInHome {
  FeelsSafeAtHome = "FSAH",
  DoesNotFeelSafe = "DNFS"
}

export enum PastVisitViewOptions {
  PastVisitTop = "top",
  PastVisitBottom = "bottom"
}

export enum DocumentSource {
  Staging = "STAGING",
  Content = "CONTENT",
  Inbox = "INBOX"
}

export enum DocumentEnum {
  From = "From",
  To = "To",
  Date = "Date",
  Name = "Name",
  Extension = "Extension",
  EncounterId = "EncounterId",
  TemplateId = "TemplateId",
  ContextData = "ContextData",
  Store = "Store",
  ContentType = "ContentType",
  claimAdjustmentId = "AccClaimAdjustmentId",
  claimReviewId = "AccClaimReviewId",
  ExternalLink = "ExternalLink",
  ExtraInfo = "ExtraInfo",
  Comment = "Comment",
  CheckedBy = "CheckedBy",
  DateChecked = "DateChecked",
  ReportType = "ReportType",
  Outcome = "Outcome",
  ShowOnTimeline = "ShowOnTimeline",
  SecGroupId = "SecGroupId",
  Acc45Summary = "Acc45Summary_",
  OriginatingDocumentId = "OriginatingDocumentId"
}

export enum DocumentTabStatus {
  New = "New",
  Edit = "Edit"
}

export enum Chronicity {
  Acute = "ACUTE",
  Chronic = "CHRONIC"
}

export enum Severity {
  Severe = "SEVERE",
  Moderate = "MODERATE",
  Mild = "MILD"
}

export enum Fracture {
  Displaced = "DISPLACED",
  Undisplaced = "UNDISPLACED"
}

export enum FractureType {
  Spiral = "SPIRAL",
  Greenstick = "GREENSTICK",
  Compound = "COMPOUND",
  Comminuted = "COMMINUTED"
}

export interface BoolObservation {
  observed?: boolean;
  comments?: string;
}

export interface GetEncounterClinicalDataArgs {
  encounterId: string;
  types?: ClinicalDataType[];
}

export enum StoreType {
  Correspondence = "COR",
  Investigations = "INV",
  ClinicalImages = "CIM",
  Prescriptions = "PRSC"
}

export enum DocumentDestinationType {
  Correspondence = "Correspondence",
  Investigations = "Investigations",
  ClinicalImages = "Clinical images",
  Prescriptions = "Prescriptions"
}

export enum CorrespondenceType {
  Email = "EML",
  Letter = "LTR",
  PatientConsent = "PTC",
  Referral = "RFL",
  Report = "RPT",
  SpecialistLetter = "SPL",
  MedicalCertificate = "MCERT",
  DischargeLetter = "DISLET",
  ExtensionTreatmentReq = "ACCEXT",
  NoticeOfDischarge = "ACCDIS",
  OperationNotes = "ACCOPNOTE",
  Custom = "CUSTOM",
  ClinicalPhotograph = "CLP",
  Autofill = "AUTOFILL",
  Perscription = "PRSC",
  FormResponse = "FRMRSP"
}

export enum CorrespondenceDirection {
  In = "CIN",
  Out = "COUT",
  Practice = "CPRACTICE"
}

export enum CorrespondenceVisibility {
  Confidential = "CON",
  DisplayOnTimeline = "DIS"
}

export interface RefCorrespondenceTypeDto
  extends RefDataDto<CorrespondenceType> {
  altText: string;
  isDocWriter: Boolean;
}
export interface ClinicalRecordDto {
  id: string;
  eTag: string;
  changeLog?: ChangeLogDto;
}

export interface DocumentWriterTab {
  documentId: string;
  patientId: string;
  encounterId?: string;
  documentTabStatus?: DocumentTabStatus;
}

export interface TemplateWriterTab {
  documentId: string;
  newDocumentId?: string;
  documentTabStatus?: DocumentTabStatus;
}

export interface PatientTab {
  patientId: string;
  encounterId?: string;
}

export enum CorrespondenceStatus {
  Draft = "DRAFT",
  Done = "DONE",
  Uploaded = "UPLOADED"
}

export interface ClinicalDocumentLite {
  dateReceived?: DateTime;
  dateChecked?: DateTime;
  checkedById?: string;
  storeIn?: string;
  fromId?: string;
  title?: string;
  id: string;
  patientId: string;
  extension?: string;
  status?: CorrespondenceStatus;
  documentDetailId: string;
  containerUri?: string;
  containerToken?: string;
  secGroupId?: string;
  outcome?: string;
}

export interface DocumentDto {
  id: string;
  patientId: string;
  to?: string;
  from?: string;
  type?: CorrespondenceType | ClaimAdjustmentDocumentTypes;
  direction: CorrespondenceDirection;
  content?: string;
  status: CorrespondenceStatus;
  store?: string;
  metadata: DocumentMetadataItem[];
  properties?: DocumentPropertiesDto;
  showOnTimeline?: boolean;
  eTag: string;
  secGroupId?: string;
  visibility?: CorrespondenceVisibility;
}
export interface AddDocumentDto extends Patch<Omit<DocumentDto, "eTag">> {
  metadata: DocumentMetadataItem[];
}

export interface DocumentMetadataItem {
  key: string;
  value: any;
}

interface DocumentPropertiesDto {
  size?: string;
}

export interface DocumentContentDto {
  content: string;
}

export interface DocumentCreateOptions {
  source: string;
  stagingId?: string;
  documents: AddDocumentDto[];
}

export interface AddEncounterDto {
  status?: EncounterStatus;
  userId: string;
  patientId: string;
  orgUnitId: string;
  visitDateTime?: string | undefined;
  encounterType?: EncounterType;
  encounterLocation?: EncounterLocation;
  episodeOfCareId?: string;
  businessRole?: string;
  secGroupId?: string;
  practiceLocationId?: string;
}

export interface PatchEncounterDto
  extends Patch<
    Omit<
      EncounterDto,
      "changeLog" | "eTag" | "orgUnitId" | "patientId" | "userId"
    >
  > {
  id: string;
  eTag: string;
}

export interface EncounterDto {
  id: string;
  eTag: string;
  status?: EncounterStatus;
  userId: string;
  patientId: string;
  orgUnitId: string;
  visitDateTime: string;
  changeLog?: ChangeLogDto;
  encounterType?: EncounterType;
  encounterLocation?: EncounterLocation;
  episodeOfCareId?: string;
  businessRole?: string;
  secGroupId?: string;
}

export interface EncounterSearchDto {
  id: string;
  eTag: string;
  status?: EncounterStatus;
  userId: string;
  patientId: string;
  orgUnitId: string;
  visitDateTime: string;
  changeLog?: ChangeLogDto;
  encounterType?: EncounterType;
  encounterLocation?: EncounterLocation;
  reasonForVisit?: ReasonForVisitClinicalDataDto;
}

export interface EncounterTimerOperationDto {
  id: string;
  timerStatus: EncounterTimerStatus;
  elapsedTime?: number;
  since: string;
}

export interface EncounterTimerDto {
  id: string;
  timerStatus: EncounterTimerStatus;
  encounterStartDateTime: string;
  elapsedTime: number;
  activeStateDateTime: string;
  changeLog: ChangeLogDto;
  eTag: string;
}

export interface MeasurementDto {
  id: string;
  type: string;
  parentType?: string;
  parentId?: string;
  patientId?: string;
  value: number;
  summary?: string;
  indicator?: string;
  eTag: string;
  changeLog?: ChangeLogDto;
  encounterId: string;
  contextId?: string;
  timestamp: string;
  secGroupId?: string;
}

export interface ObservationDto {
  id: string;
  type: string;
  encounterId: string;
  patientId?: string;
  timestamp: string;
  values: ObservationValue[];
  summary?: string;
  eTag: string;
  changeLog?: ChangeLogDto;
  secGroupId?: string;
}

export interface ObservationValue {
  id: string;
  value: number;
  unit?: string;
  summary?: string;
  metadata?: ObservationMetadataItem[];
  contextId?: string;
  changeLog?: ChangeLogDto;
}

export interface ObservationMetadataItem {
  key: string;
  value: any;
}

export interface InteractionDto {
  type: string;
  orgUnitId: string;
  title: string;
  text?: string;
  userId: string;
  patientId: string;
  sourceId: string;
  eTag: string;
  id: string;
  timestamp: string;
  interactionDateTime: string;
  sourceEntity?: string;
  sourceTypeEntity?: string;
  sourceTypeCode?: string;
  secGroupId?: string;
}

export type GetInteractionsDto = PagingOptions & {
  patientId?: string;
};

export type GetDocumentsDto = PagingOptions & {
  patientId: string;
  direction?: string;
  store?: string;
  types?: string[];
  statuses?: string[];
  searchText?: string;
};

export interface DocumentUrlDto {
  url: string;
}

export type GetEncountersDto = PagingOptions & {
  patientId: string;
  search?: string;
  statuses?: string[];
  userIds?: string[];
  reasons?: string[];
  dateFrom?: string;
  dateTo?: string;
  encounterTypeCodes?: string[];
  episodeOfCareIds?: string[];
  encounterIds?: string[];
};

export type GetReasonForVisitClinicalDto = PagingOptions & {
  patientId?: string;
  statuses?: string[];
  userIds?: string[];
  reasons?: string[];
};

export interface SubstanceUseTypeDto {
  id: string;
  snomed: string;
  category: string;
  code: string;
  text: string;
}

export type SubstanceUseTypeFilter = PagingOptions & {
  search?: string;
};

export type QuestionnaireAnswerOptionDto = {
  text: string;
  value: string;
};

export type QuestionnaireItemDto = {
  id: number;
  prefix?: string;
  text: string;
  answerOptions?: QuestionnaireAnswerOptionDto[];
  heading?: string;
  minTitle?: string;
  maxTitle?: string;
};

export type QuestionnaireDto = {
  id: string;
  code: string;
  text: string;
  changeLog?: ChangeLogDto;
  eTag: string;
  items: QuestionnaireItemDto[];
  secGroupId?: string;
};

export type SpecialTestAnswerConditionDto = {
  questionId: number;
  is?: string[];
  not?: string[];
};

export enum SpecialTestAnswerSpecialCondition {
  Any = "ANY"
}

export type SpecialTestItemDto = QuestionnaireItemDto & {
  index?: number;
  type: string;
  range?: number;
  heading?: string;
  placeholder?: string;
  requiredConditions?: SpecialTestAnswerConditionDto[];
};

export enum QuestionnaireItemType {
  MultiChoice = "MLC",
  Integer = "INT",
  String = "STR",
  Date = "DATE",
  Time = "TIME"
}

export enum SpecialTestTypeCode {
  Special = "SPECIAL",
  Strength = "STRENGTH",
  CentralNervous = "CENTRANER"
}

export type SpecialTestDto = QuestionnaireDto & {
  hasSide?: boolean;
  typeCode?: SpecialTestTypeCode;
  items: SpecialTestItemDto[];
};

export interface QuestionnaireItem {
  id?: string;
  answer: string;
  questionnaireItemId: number;
}

export interface SpecialTestResponseItem {
  id?: string;
  specialTestItemId: number;
  specialTestItemType: string;
  side?: string;
  value: string;
}

export interface QuestionnaireResponseDto extends ClinicalDataDocumentBaseDto {
  id?: string;
  questionnaireId: string;
  questionnaireCode: string;
  items: QuestionnaireItem[];
  contextId?: string;
  secGroupId?: string;
}

export interface SpecialTestResponseDto extends ClinicalDataDocumentBaseDto {
  id?: string;
  specialTestId: string;
  specialTestCode: string;
  items: SpecialTestResponseItem[];
}

export type GetEncounterClinicalDataDto = {
  encounterId: string;
  types?: string[];
};

export type GetPatientClinicalDataDto = {
  patientId: string;
  types?: string[];
  encounterId?: string;
};

export type GetEpisodeOfCareClinicalDataDto = {
  patientId: string;
  episodeOfCareId?: string;
  types?: string[];
};

export type GetEpisodesOfCareClinicalDataDto = {
  patientId: string;
  episodesOfCareIds: string[];
  types?: string[];
};

export interface MedicationClinicalDataItemDto
  extends ClinicalDataElementWithDeleteDto {
  id?: string;
  packId: number;
  productName: string;
  isPrescribed?: boolean;
  isCeased?: boolean;
  ceasedDate?: string;
  ceasedComment?: string;
  reasonForCease?: string;
  dose?: number;
  complexInstructions?: string;
  duration?: number;
  doseUnit?: string;
  frequency?: string;
  food?: string;
  route?: string;
  durationUnit?: MedicationDurationUnit;
  otherInstructions?: string;
  quantity?: number;
  repeats?: number;
  isLongTermMedication?: boolean;
  regulation49?: boolean;
  brandSubstitute: boolean;
  printBrandName?: boolean;
  rxNote?: string;
  rxType?: string;
  strength?: string;
  prn?: boolean;
  warnings?: ReactionWarningDto[];
  acceptedWarnings?: boolean;
  firstRx?: string;
  lastRx?: string;
  medicationStartedBy?: string;
  medicationStarted?: string;
  reasonForPrescription?: ReasonForPrescriptionItem;
}

interface ReasonForPrescriptionItem {
  medHxId: string;
  diagnosisCode: CodedFieldClinicalDataItemDto;
}
export enum MedicationDurationUnit {
  Day = "DAY",
  Week = "WEEK",
  Month = "MONTH",
  UntilFinished = "UNTIL"
}

export interface AmendmentDto {
  id: string;
  encounterId: string;
  text: string;
  changeLog?: ChangeLogDto;
}

export interface AddAmendmentDto {
  id?: string;
  encounterId: string;
  text: string;
}

export type NatureOfReactionSeverity = {
  natureOfReaction: NatureOfReaction | string;
  severity: ReactionSeverity;
  otherText?: string;
};

export enum ClinicalDataType {
  EliteAthlete = "EA",
  Pregnancy = "PRE",
  Social = "SOC",
  PhysicalActivity = "PA",
  Eye = "EYE",
  Medication = "MED",
  Alcohol = "ALC",
  MedicalHistory = "MDH",
  ReasonForVisit = "RFV",
  ReactionsChecked = "RCK",
  Reaction = "RCN",
  K10 = "K10",
  K10View = "k10view",
  RAND36 = "RAND36",
  RAND36View = "rand36view",
  OREBRO = "OREBRO",
  OREBROView = "orebroview",
  NPRS = "NPRS",
  NPRSView = "nprsview",
  HIP = "HipV1",
  HAN = "HAN",
  SPI = "SPINE",
  GEN = "GEN",
  FOO = "FOOT",
  SHO = "SHO",
  Tobacco = "TBC",
  SubstanceUse = "SBU",
  FullBody = "FB",
  BodyExam = "BODEXAM",
  AlcoholConfirmed = "ACM",
  TobaccoConfirmed = "TCM",
  SubstanceUseConfirmed = "SUCM",
  SocialHistoryConfirmed = "SCM",
  PhysicalActivityConfirmed = "PCM",
  BodyAreaConfirmed = "BACM",
  PeopleDemographicUpdate = "PDU",
  PSFS = "PSFS",
  PSFSView = "psfsview",
  PSFSContext = "PSFSContext",
  GRCS = "GRCS",
  GRCSView = "grcsview",
  DASH = "DASH",
  DASHView = "dashview",
  WorkHistory = "WH",
  WorkHistoryConfirmed = "WHCM",
  ClinicalTask = "TASK",
  PhysioBodyDiagram = "PHYBOD",
  PhysioMedicalHistory = "PMH",
  CurrentInjury = "CI",
  CustomClinicalTool = "CCT",
  CustomClinicalToolView = "customview",
  CustomClinicalToolContext = "CCTC",
  PostureOrObservations = "POOB",
  OtherMovements = "OTHMOV",
  TreatmentAndEducation = "TE",
  InjuryArea = "IA",
  Goals = "GOL",
  FollowUpNotes = "FUN",
  Diagnoses = "DIA",
  Discharge = "DIS",
  ClaimReview = "CLRV",
  ConsentAndWarnings = "CAW",
  PatientTreatmentPlan = "PTP",
  TreatmentPlan = "TP",
  DocumentOperations = "DO",
  DischargeReferralFollowUp = "DRF",
  Sleep = "SLE",
  SleepConfirmed = "SLCM",
  GeneralExamination = "GE",
  GeneralExamConfirmed = "GECM",
  AnalysisAndPlan = "AAP",
  ClinicalFlags = "FLA",
  ClinicalFlagsConfirmed = "FLACM",
  DermatomesAndMyotomes = "DM",
  FamilyHistory = "FAMHIS",
  FamilyHistoryConfirmed = "FAMHISCM",
  DermatomesAndMyotomesConfirmed = "DMCM",
  SystemsReview = "SR",
  SystemsReviewConfirmed = "SRCM",
  KNE = "KNEE",
  ELB = "ELB",
  HofPCConfirmed = "HOPCC",
  CentralNervousSystem = "CNS",
  CentralNervousSystemConfirmed = "CNSCM",
  EPDS = "EPDS",
  EPDSView = "EPDSView",
  DASS21 = "DASS21",
  DASS21View = "DASS21view",
  Observations = "OBS", // Note: POC - May not end up being a clinical data type and might need it's own type introduced (e.g. clinical tab)
  MedicationsAndSupplements = "MAS",
  MedicationsAndSupplementsConfirmed = "MASCM",
  ClinicalActivity = "ACT",
  Prescriptions = "PRE"
}

export enum QuestionnaireType {
  DASHV1 = "dashv1",
  DASS21V1 = "DASS21V1",
  EPDSV1 = "EPDSV1",
  GRCSV1 = "GRCSV1",
  K10V1 = "K10V1",
  NPRSV1 = "NPRSV1",
  OREBROV1 = "OREBROV1",
  OSWESTRY = "OSWESTRY",
  PSFSV1 = "PSFSV1",
  RAND36 = "RAND36"
}

export enum PhysitrackType {
  Key = "physitrack",
  Text = "Physitrack"
}

export enum MeasurementType {
  DASH = "DASH",
  GRCS = "GRCS",
  K10 = "K10",
  NPRS = "NPRS",
  OREBRO = "OREBRO",
  PSFS = "PSFS",
  CustomClinicalTool = "CCT",
  RAND36 = "RAND36",
  EPDS = "EPDS",
  DASS21 = "DASS21"
}

export enum IntakeStatuses {
  DrinksAlcohol = "DRNK",
  ExDrinker = "EXDR",
  NeverDrinker = "NEVR",
  ActiveParticipant = "ACTPRPNT",
  ExParticipant = "EXPRPNT",
  NeverParticipant = "NVRPRPTD",
  ActiveSmoker = "ACTSMKR",
  ExSmoker = "EXSMKR",
  NeverSmoker = "NVRSMKD"
}

export enum PermanentClinicalTab {
  TodaysNotes = "TN",
  PatientSummary = "PS",
  SOTAP = "SO"
}

export enum AccClinicalTab {
  Injury = "Injury",
  Adjustment = "ACC32",
  Review = "Review"
}

export enum TemplateFormatType {
  SyncFusionDocx = "SyncFusionDocx"
}

export enum TemplateTypeCode {
  Clinical = "CLIN",
  All = "ALL"
}

export enum DocumentContentType {
  Sfdt = "sfdt",
  Pdf = "pdf",
  Html = "html",
  ApplicationPdf = "application/pdf"
}

export enum DocumentExtensionType {
  Docx = "docx",
  Doc = "doc",
  Rtf = "rtf",
  Html = "html"
}

export enum PurposeType {
  DOC = "Document",
  AUTO = "Autofill"
}

export enum AutofillDialogPurposeType {
  NEW = "New",
  UPDATE = "Update existing"
}

export type DockableClinicalTab =
  | ClinicalDataType
  | PermanentClinicalTab
  | AccClinicalTab;

export interface TemplateDto {
  id: string;
  name: string;
  content: string;
}

export interface RefReactionTypeDto extends RefDataDto<string> {
  warningText: string;
}

export interface RefEncounterTypeDto extends RefDataDto<string> {
  virtual?: boolean;
}

export type ReactionWarningDto = {
  type: string;
  warning: string;
};

export interface GetReactionWarningsArgs {
  patientId: string;
  packId: number;
}

export interface RefSexualityDto extends RefDataDto<string> {
  concept: string;
  effectiveDate?: Date;
  endDate?: Date;
}

// Primary terminology object
export type TerminologyDto = {
  code: string;
  codeSystem?: string;
  text: string;
  readCodes?: string[];
  version?: string;
  isReasonForVisit?: boolean;
  isDiagnosis?: boolean;
  isProcedure?: boolean;
  isCauseOfDeath?: boolean;
  isSide?: boolean;
  isChronicity?: boolean;
  isSeverity?: boolean;
  isFracture?: boolean;
  aCCAcceptable?: boolean;
  aCC32Acceptable?: boolean;
  readCode?: string;
};

// Full details lookup for a single code
export type TerminologyConceptDto = {
  code: string;
  codeSystem: string;
  version?: string;
  termNames: string[];
  searchTerms: string[];
  reasonForVisit?: boolean;
  diagnosis?: boolean;
  procedure?: boolean;
  causeOfDeath?: boolean;
  side?: boolean;
  chronicity?: boolean;
  severity?: boolean;
  fracture?: boolean;
  aCCAcceptable?: boolean;
  aCC32Acceptable?: boolean;
  readCode?: string;
  readCodes?: string[];
  isPrimaryDiagnosis?: boolean;
  diagnosisSide?: string;
};

export type TerminologySearchRequest = PagingOptions & {
  search: string;
  isReasonForVisit?: boolean;
  isDiagnosis?: boolean;
  isProcedure?: boolean;
  isCauseOfDeath?: boolean;
  isSide?: boolean;
  isChronicity?: boolean;
  isSeverity?: boolean;
  isFracture?: boolean;
};

export type TerminologyLookupRequest = {
  code?: string;
  codeSystem?: string;
  version?: string;
};

export enum NatureOfReaction {
  Anaphylaxis = "0",
  Rash = "1",
  Bronchospasm = "2",
  Oedema = "3",
  Nausea = "4",
  Vomiting = "5"
}

export enum ReactionSeverity {
  Mild = "MILD",
  Moderate = "MODERATE",
  Severe = "SEVERE"
}

export enum ReactionType {
  DrugClass = "0",
  Ingredient = "1",
  SpecificProduct = "2",
  NonDrug = "3",
  Other = "4"
}

export enum DescriptionTag {
  Class = "(Class)",
  NonDrug = "(non-drug)"
}

export enum ReactionCertainty {
  Confirmed = "CON"
}

export enum Sexuality {
  Bisexual = "BI",
  Heterosexual = "HETERO",
  Homosexual = "HOMO"
}

export enum DASHModuleTypes {
  Disability = "Disability",
  Work = "Work",
  Sport = "Sport/Music"
}

export enum PatientSummary {
  FamilySocial = "FS",
  ClinicalTasks = "PHRR",
  ClinicalNotifications = "ACT",
  CommentsFlags = "CF",
  MedicalHistory = "MDH",
  Medication = "CRX",
  RecentInvestigationsDocument = "RID",
  RecentObservations = "RO",
  NotesFromPreviousEncounter = "NPE",
  ReasonForVisit = "RFV",
  ClinicalTools = "CT"
}

export enum DockView {
  TodaysNotes = "TN",
  PatientSummary = "PS",
  Sotap = "SO"
}

export enum TreeView {
  Timeline = "TL",
  PastConsults = "PV",
  MedicalHistory = "MDH",
  Correspondence = "CORR",
  TreatmentPlan = "TP",
  Medications = "MED",
  PastPrescriptions = "PP",
  CeasedMedications = "CM",
  HistoryExamination = "HE",
  ClinicalTools = "CT",
  FamilyAndSocialHistory = "FSH",
  Conditions = "CND",
  Investigations = "INV",
  ClinicalImages = "CI",
  Observations = "OBS"
}

export enum ClinicalNoteFormat {
  Default = "DEFAULT",
  SOTAP = "SOTAP",
  SOAP = "SOAP"
}

export enum ClinicalWorkflowFormat {
  None = "NONE",
  SOTAP = "SOTAP"
}

export enum IntakeUnits {
  StandardDrink = "STD"
}

export enum IntakeTypes {
  Regular = "ALCHOL"
}

export enum IntakeTypesFormTitle {
  AlcoholTitle = "Alcohol",
  SubstanceUseTitle = "Substance use",
  TobaccoTitle = "Tobacco, e-cigarettes, vaping"
}

export enum NoteType {
  CurrentUser = "CurrentUser",
  OtherUser = "OtherUser"
}

export enum InjuryArea {
  Spine = "SPI",
  HandAndWrist = "HAN",
  Knee = "KNE",
  AnkleFoot = "FOO",
  Hip = "HIP",
  Shoulder = "SHO",
  Elbow = "ELB",
  Thumb = "THU",
  Finger = "FIN"
}

export enum DisplayOutcome {
  Resolved = "RES",
  PartiallyResolved = "PRES",
  Unresolved = "URES"
}

export enum DisplayOutcomeDetail {
  FullRecovery = "FRES",
  SelfManageToFullRecovery = "ERES",
  HappyToSelfManage = "SPRES",
  NecessaryOngoingManagementPlans = "NPRES",
  SpecialistReferral = "WPRES",
  PoorAttendee = "PURES",
  UnableToAttend = "UURES",
  WillBenefitFromSpecialistReferral = "WURES"
}

export enum TemplateVisibility {
  Clinical = "C",
  Admin = "A",
  Both = "B"
}

export enum SubmitActionCode {
  PublishToEveryoneCode = "PTE",
  PublishToSelfCode = "PTS",
  DraftCode = "DRA",
  Published = "Published"
}

export interface CodedFieldClinicalDataItemDto
  extends ClinicalDataElementBaseDto {
  id?: string;
  code: string;
  originalText: string;
  codeSystem?: string;
  version?: string;
  isDeleted?: boolean;
  deletedComment?: string;
}

export interface CodedText {
  code: string;
  text: string;
  codeSystem?: string;
  version?: string;
}

export interface ReasonForVisitClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  reasonForVisits: CodedFieldClinicalDataItemDto[];
  secGroupId?: string;
}

export interface UserIdentifier {
  userId: string;
}

export interface ClinicalDataElementCreateLog {
  createdById: string;
  createdDateTime: string;
  createdEncounterId: string;
}

export interface ClinicalDataElementUpdateLog {
  updatedById: string;
  updatedDateTime: string;
  updatedEncounterId: string;
  updatedFromEncounterId: string;
}

interface MedicationClinicalDataDto extends ClinicalDataDocumentBaseDto {
  medications: MedicationClinicalDataItemDto[];
}

export interface PrescriptionsClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  prescriptions: PrescriptionClinicalDataItemDto[];
}

export interface PrescriptionClinicalDataItemDto
  extends ClinicalDataElementBaseDto {
  id?: string;
  scriptId?: number;
  documentId?: string;
  isDeleted: boolean;
  medications: MedicationClinicalDataItemDto[];
}

export interface ReactionClinicalDataDto extends ClinicalDataDocumentBaseDto {
  agents?: AgentClinicalDataItemDto[];
  reactions?: ReactionClinicalDataItemDto[];
  nilKnown?: boolean;
}

interface ReactionsCheckedClinicalDataDto extends ClinicalDataDocumentBaseDto {
  reactionsCheckedAndUpdated: ReactionsCheckedAndUpdatedClinicalDataDto;
}

interface ClinicalDataConfirmedorUpdatedClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  confirmed?: boolean;
}

interface AlcoholConfirmedClinicalDataDto
  extends ClinicalDataConfirmedorUpdatedClinicalDataDto {}
interface TobaccoConfirmedClinicalDataDto
  extends ClinicalDataConfirmedorUpdatedClinicalDataDto {}
interface SubstanceUseConfirmedClinicalDataDto
  extends ClinicalDataConfirmedorUpdatedClinicalDataDto {}
export interface SocialHistoryConfirmedClinicalDataDto
  extends ClinicalDataConfirmedorUpdatedClinicalDataDto {}
interface PhysicalActivityConfirmedClinicalDataDto
  extends ClinicalDataConfirmedorUpdatedClinicalDataDto {}
export interface WorkHistoryConfirmedClinicalDataDto
  extends ClinicalDataConfirmedorUpdatedClinicalDataDto {}
export interface AgentClinicalDataItemDto
  extends ClinicalDataElementWithDeleteDto {
  id?: string;
  agent: CodedFieldClinicalDataItemDto;
  comment?: string;
}

export interface ReactionClinicalDataItemDto
  extends ClinicalDataElementWithDeleteDto {
  id?: string;
  agent: CodedFieldClinicalDataItemDto;
  type: ReactionType;
  natureOfReaction: NatureOfReaction | string;
  severity: ReactionSeverity;
  certainty: string;
  otherText?: string;
}

export interface ReactionsCheckedAndUpdatedClinicalDataDto
  extends ClinicalDataElementBaseDto {
  id?: string;
  value: boolean;
}

export interface MedicalHistoryClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  medicalHistories?: MedicalHistoryClinicalDataItemDto[];
  noSignificantHistory: boolean;
}

export interface MedicalHistoryClinicalDataItemDto
  extends ClinicalDataElementWithDeleteDto {
  id?: string;
  diagnosisDate?: string;
  patientAge?: number;
  clinicallySignificant?: boolean;
  active: boolean;
  details?: string;
  certainty?: MedicalCertainty;
  diagnosis?: string;
  diagnosisCode?: CodedFieldClinicalDataItemDto;
  diagnosisSide?: string;
  diagnosisSideSelected?: string[];
  isDiagnosis?: boolean;
  isProcedure?: boolean;
  episodeOfCareId?: string;
  secGroupId?: string;
  severity?: Severity;
  chronicity?: Chronicity;
  fracture?: Fracture;
  fractureTypes?: string[];
  isPrimary?: boolean;
}

export interface K10ClinicalDataDto extends QuestionnaireResponseDto {}
export interface NPRSClinicalDataDto extends QuestionnaireResponseDto {}
export interface OREBROClinicalDataDto extends QuestionnaireResponseDto {}
export interface Rand36ClinicalDataDto extends QuestionnaireResponseDto {}
export interface EPDSClinicalDataDto extends QuestionnaireResponseDto {}
export interface DASS21ClinicalDataDto extends QuestionnaireResponseDto {}

export interface EpisodeOfCareClinicalDataDto extends EncounterClinicalDataDto {
  episodeOfCareId: string;
}

export interface SignificantFamilyHistoryDataItemDto {
  id?: string;
  relationship: string;
  conditions?: SignificantConditionDataItemDto[];
  diagnosisKeys?: string[];
}

export interface SignificantConditionDataItemDto {
  id?: string;
  condition: CodedFieldClinicalDataItemDto;
  diagnosisKey?: string;
  comment?: string;
}
export interface EncounterClinicalDataDto {
  reaction?: ReactionClinicalDataDto;
  reactionsChecked?: ReactionsCheckedClinicalDataDto;
  medication?: MedicationClinicalDataDto;
  alcohol?: AlcoholClinicalDataDto;
  physicalActivity?: PhysicalActivityClinicalDataDto;
  social?: SocialClinicalDataDto;
  tobacco?: TobaccoClinicalDataDto;
  substanceUse?: SubstanceUseClinicalDataDto;
  medicalHistory?: MedicalHistoryClinicalDataDto;
  k10?: K10ClinicalDataDto;
  nprs?: NPRSClinicalDataDto;
  orebro?: OREBROClinicalDataDto;
  measurement?: MeasurementDto[];
  observations?: ObservationDto[];
  fullBody?: FullBodyClinicalDataDto;
  reasonForVisit?: ReasonForVisitClinicalDataDto;
  alcoholConfirmed?: AlcoholConfirmedClinicalDataDto;
  tobaccoConfirmed?: TobaccoConfirmedClinicalDataDto;
  substanceUseConfirmed?: SubstanceUseConfirmedClinicalDataDto;
  socialHistoryConfirmed?: SocialHistoryConfirmedClinicalDataDto;
  physicalActivityConfirmed?: PhysicalActivityConfirmedClinicalDataDto;
  patientDemographicUpdate?: PatientDemographicUpdateClinicalDataDto;
  psfs?: PSFSMultipleQuestionnaireResponseDto;
  psfsContext?: PSFSContextClinicalDataDto;
  grcs?: GRCSQuestionnaireResponseDto;
  dash?: DASHQuestionnaireResponseDto;
  workHistory?: WorkHistoryClinicalDataDto;
  workHistoryConfirmed?: WorkHistoryConfirmedClinicalDataDto;
  clinicalTask?: ClinicalTaskClinicalDataDto;
  physioBody?: PhysioInjuryAreaClinicalDataDto;
  physioMedicalHistory?: PhysioMedicalHistoryClinicalDataDto;
  postureOrObservations?: PostureOrObservationsDto;
  otherMovements?: OtherMovementsDto;
  currentInjury?: CurrentInjuryClinicalDataDto;
  customClinicalTool?: CustomClinicalToolClinicalDataDto;
  customClinicalToolContext?: CustomClinicalToolContextClinicalDataDto;
  analysisAndPlan?: AnalysisAndPlanClinicalDataDto;
  treatmentAndEducation?: TreatmentAndEducationClinicalDataDto;
  injuryArea?: InjuryAreaClinicalDataDto;
  followUpNotes?: FollowUpNotesClinicalDataDto;
  diagnoses?: DiagnosesClinicalDataDto;
  injury?: InjuryClinicalDataDto;
  discharge?: DischargeClinicalDataDto;
  claimReview?: ClaimReviewClinicalDataDto;
  patientTreatmentPlan?: PatientTreatmentPlanClinicalDataDto;
  consentAndWarnings?: ConsentAndWarningsDataDto;
  goals?: GoalsClinicalDataDto;
  treatmentPlan?: TreatmentPlanClinicalDataDto;
  documentOperations?: DocumentOperationsClinicalDataDto;
  imagingRequests?: ImagingRequestsDataDto;
  imagingReports?: ImagingReportsDataDto;
  sleep?: SleepClinicalDataDto;
  sleepConfirmed?: SleepConfirmedClinicalDataDto;
  generalExamination?: GeneralExaminationClinicalDataDto;
  generalExamConfirmed?: GeneralExamConfirmedClinicalDataDto;
  bodyArea?: BodyAreaExaminationClinicalDataDto;
  bodyAreaConfirmed?: BodyAreaExaminationConfirmedClinicalDataDto;
  amendment?: AmendmentClinicalDataDto;
  clinicalFlags?: ClinicalFlagsClinicalDataDto;
  clinicalFlagsConfirmed?: ClinicalFlagsConfirmedClinicalDataDto;
  hofpcConfirmed?: HofPCConfirmedClinicalDataDto;
  dermatomesAndMyotomes?: DermatomesAndMyotomesClinicalDataDto;
  familyHistory?: FamilyHistoryClinicalDataDto;
  familyHistoryConfirmed?: FamilyHistoryConfirmedClinicalDataDto;
  dermatomesAndMyotomesConfirmed?: DermatomesAndMyotomesConfirmedClinicalDataDto;
  systemsReview?: SystemsReviewClinicalDataDto;
  systemsReviewConfirmed?: SystemsReviewConfirmedClinicalDataDto;
  rand36?: Rand36ClinicalDataDto;
  centralNervousSystem?: CentralNervousSystemClinicalDataDto;
  centralNervousSystemConfirmed?: CentralNervousSystemConfirmedClinicalDataDto;
  epds?: EPDSClinicalDataDto;
  dass21?: DASS21ClinicalDataDto;
  clinicalActivity?: ClinicalActivityClinicalDataDto;
  medicationsAndSupplements?: MedicationsAndSupplementsClinicalDataDto;
  medicationsAndSupplementsConfirmed?: MedicationsAndSupplementsConfirmedClinicalDataDto;
  prescriptions?: PrescriptionsClinicalDataDto;
}

export interface TreatmentPlanClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  dataItems?: TreatmentPlanDataItemDto[];
}

export interface ConsentAndWarningsDataDto extends ClinicalDataDocumentBaseDto {
  eocId?: string;
  treatmentPlanDiscussed?: boolean;
  warningExplained?: boolean;
  consentObtained?: boolean;
  culturalNeedsIdentified?: boolean;
  additionalDiscussions?: string;
}

export interface TreatmentPlanDataItemDto extends ClinicalDataDocumentBaseDto {
  id?: string;
  treatments?: TreatmentDataItemDto[];
  otherTreatments?: string;
  educationOptions?: string[];
  otherEducationComment?: string;
  educationComment?: string;
  plan?: string;
  businessRoleCode?: string;
}

export interface GoalsClinicalDataDto extends ClinicalDataDocumentBaseDto {
  dataItems?: GoalsDataItemDto[];
}
export interface GoalsDataItemDto extends ClinicalDataDocumentBaseDto {
  id?: string;
  claimGoals?: ClaimGoalsDataItemDto[];
  businessRoleCode?: string;
}
export interface ClaimGoalsDataItemDto extends ClinicalDataElementBaseDto {
  psfsGoalsAdded?: boolean;
  goals?: GoalDataItemDto[];
}

export interface FullBodyClinicalDataDto extends ClinicalDataDocumentBaseDto {
  examinationComment?: string;
  frontImage?: string;
  backImage?: string;
  leftSideImage?: string;
  rightSideImage?: string;
}

export enum FullBodyCodes {
  FemaleFront = "FFBF",
  FemaleBack = "FFBB",
  FemaleLeftSide = "FFBS",
  FemaleRightSide = "FFBRS",
  MaleFront = "MFBF",
  MaleBack = "MFBB",
  MaleLeftSide = "MFBS",
  MaleRightSide = "MFBRS"
}

export interface SocialClinicalDataDto extends ClinicalDataDocumentBaseDto {
  sexuality?: string;
  isPregnant?: BoolObservation;
  isBreastfeeding?: BoolObservation;
  isAdvancedCareDirective?: BoolObservation;
  accommodation?: string;
  livesWith?: string;
  safetyInHome?: string;
}

export enum DominantHand {
  Left = "L",
  Right = "R",
  Ambidextrous = "A"
}

export interface PhysicalActivityClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  isEliteAthlete?: BoolObservation;
  hoursSpentAtSedentary?: number;
  activities?: PhysicalActivityDto[];
  dominantHand?: DominantHand;
}

export interface PhysicalActivityDto extends PhysicalActivityBaseDto {
  sessionsPerWeek: number;
  sessionLength: number;
}

export interface PhysicalActivityFormDto extends PhysicalActivityBaseDto {
  sessionsPerWeek: string;
  sessionLength: string;
}

interface PhysicalActivityBaseDto {
  id: string;
  activity: string;
  activityOtherText?: string;
  level?: string;
  isDeleted: boolean;
}

export interface WorkHistoryClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  workType?: string;
}

export interface PatientDemographicUpdateClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  relationshipStatus?: string;
  isEnduringPowerOfAttorney?: BoolObservation;
  powerOfAttorneys?: EnduringPowerOfAttorneyDto[];
  hasACareGiver?: BoolObservation;
  isACarer?: BoolObservation;
  careGivers?: CareGiverDto[];
  careRecipients?: CareRecipientDto[];
  occupation?: string;
}
export interface CareGiverDto {
  id: string;
  careGiverTypes: string[];
}

export interface CareRecipientDto {
  id?: string;
  careRecipientTypes: string[];
  assigned?: boolean;
}

export interface EnduringPowerOfAttorneyDto {
  id: string;
}

export interface ClinicalDataElementBaseDto {
  createLog?: ClinicalDataElementCreateLog;
  updateLog?: ClinicalDataElementUpdateLog;
}

export interface ClinicalDataDocumentBaseDto
  extends ClinicalDataElementBaseDto {
  eTag?: string;
}

export interface ClinicalDataElementWithDeleteDto
  extends ClinicalDataElementBaseDto {
  isDeleted?: boolean;
  reasonForDelete?: string;
  deletedComment?: string;
}

export interface ObservationTypeRefDataDto extends RefDataDto {
  abbreviatedName?: string;
}

export interface IntakeFlagRefDataDto<T extends string> extends RefDataDto<T> {
  isAlcohol?: boolean;
  isTobacco?: boolean;
  isSubstanceUse?: boolean;
  category?: string;
  snomed?: string;
  eTag: string;
}

export interface ImagingModalityRefDataDto extends RefDataDto {
  snomed?: string;
  eTag: string;
}

export interface ImagingRegionsRefDataDto extends RefDataDto {
  snomed?: string;
  hasSide?: boolean;
  weightBearing?: boolean;
  eTag: string;
}
export interface IntakeModelDto extends ClinicalDataDocumentBaseDto {
  comment?: string;
  status?: string;
  intakes: IntakeItemDto[];
}

export interface IntakeItemDto extends ClinicalDataElementWithDeleteDto {
  type: string;
  amount?: number;
  occurrence?: number;
  frequency: string;
  yearStarted?: number;
  isCurrent: boolean;
  id?: string;
  unit?: string;
  yearStopped?: number;
  cessation?: CessationDto;
  isLocked?: boolean;
}
export interface CessationDto extends ClinicalDataElementWithDeleteDto {
  id?: string;
  isAdviceGiven: boolean;
  advices?: AdviceItemDto[];
}

interface AdviceItemDto extends ClinicalDataElementBaseDto {
  id?: string;
  type: string;
}

export interface TobaccoClinicalDataDto extends IntakeModelDto {
  exSmokerLessThan12Months?: boolean;
}

export interface SubstanceUseClinicalDataDto extends IntakeModelDto {}

export interface AlcoholClinicalDataDto extends IntakeModelDto {}

export interface MultipleQuestionnaireResponseDto
  extends ClinicalDataDocumentBaseDto {
  id?: string;
}

export interface PSFSMultipleQuestionnaireResponseDto
  extends MultipleQuestionnaireResponseDto {
  responses: PSFSQuestionnaireResponseDto[];
}

export interface PSFSQuestionnaireResponseDto extends QuestionnaireResponseDto {
  reason: string;
}

export interface GRCSQuestionnaireResponseDto extends QuestionnaireResponseDto {
  diagnosis?: CodedFieldClinicalDataItemDto;
  side?: string;
  secGroupId?: string;
}

export interface DASHQuestionnaireResponseDto
  extends QuestionnaireResponseDto {}

export interface Rand36QuestionnaireResponseDto
  extends QuestionnaireResponseDto {}

export interface PSFSContextClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  contexts: PSFSContextClinicalDataItemDto[];
}

export interface ResponseContextClinicalDataItemDto
  extends ClinicalDataElementBaseDto {
  id?: string;
  contextId: string;
  secGroupId?: string;
}

export interface PSFSContextClinicalDataItemDto
  extends ResponseContextClinicalDataItemDto {
  diagnosis: CodedFieldClinicalDataItemDto;
  side: string;
  activities: PSFSActivityItemDto[];
  isClosed: boolean;
  reasonForClose?: string;
  closedComment?: string;
  claimId?: string;
}

export interface PSFSActivityItemDto {
  id?: string;
  text: string;
  questionnaireItemId: number;
}

export interface GetStructuredNoteDto {
  encounterId?: string;
  types?: string[];
}

export interface StructuredNoteDto {
  heading: StructuredNoteHeading;
  section: StructuredNoteHeading;
  note: string;
}

export interface StructuredNoteHeading {
  name: string;
  order: number;
  code: string;
}

export interface TodaysNotes {
  freeText?: string;
  headings?: TodaysNotesHeading[];
}

export interface TodaysNotesHeading extends StructuredNoteHeading {
  structuredNotes?: TodaysNotesStructuredNote[];
  freeText?: string;
}

export interface TodaysStructuredNotesUserSettings {
  showImage: boolean;
}

export interface TodaysNotesStructuredNote {
  type: string; //clinical note data heading
  order: number;
  note: string; //clinical note data text
  sectionCode: string; //clinical note section code
}

export interface EncounterClinicalNoteDto {
  sectionElements?: ClinicalNoteSectionElement[];
  clinicalDataElements: ClinicalNoteDataElement[];
  sectionHeadingReferenceData: ClinicalNoteHeadingData[];
  clinicalDataHeadingReferenceData: ClinicalNoteHeadingData[];
}

export interface ClinicalNoteSectionElement {
  sectionCode: string;
  text: string;
}

export interface ClinicalNoteDataElement {
  sectionCode: string;
  headingCode: string;
  text: string;
}

export interface ClinicalNoteHeadingData {
  code: string;
  text: string;
  order?: number;
}

export interface RefClinicalDataTypeDto extends RefDataDto<string> {
  isPatientScoped?: boolean;
  isEpisodeScoped?: boolean;
  isEncounterScoped?: boolean;
}

export interface EncounterSearchDto extends EncounterDto {
  reasonForVisit?: ReasonForVisitClinicalDataDto;
}

export interface RefClinicalTaskTypeDto extends RefDataDto<string> {
  snomedConceptId: string;
}

export interface RefClinicalActivityTypeDto extends RefDataDto<string> {}
export interface RefClinicalActivityDescriptionDto extends RefDataDto<string> {
  snomedConceptId: string;
  task: boolean;
  reminder: boolean;
}

export interface ClinicalFlagsDto extends RefDataDto<string> {
  clinicalFlagText: string;
  isSOTAP: boolean;
  isRed: boolean;
  isYellow: boolean;
  isSystemic: boolean;
  isNeuro: boolean;
  isENT: boolean;
  isCardio: boolean;
  isGastro: boolean;
  isUrinary: boolean;
  isGynae: boolean;
  isMusculo: boolean;
  isHaemato: boolean;
}

export interface NervesDto extends RefDataDto<string> {
  myotome: boolean;
  trigeminal: boolean;
  cervical: boolean;
  thoracic: boolean;
  lumbosacral: boolean;
}

export interface RefInjuryAreaMotionTypeGroupDto extends RefDataDto<string> {
  injuryAreas: string;
}

export interface RefMotionTypeDto extends RefDataDto<string> {
  injuryAreaMotionTypeGroups: string;
}

export interface RefTreatmentOptionDto extends RefDataDto<string> {
  businessRoles?: string;
  acceptableSynonyms?: string;
  snomed?: string;
}

export interface RefMeridianDto extends RefDataDto<string> {
  pinyin?: string;
  isPrimary?: boolean;
}

export interface RefAcupointDto extends RefDataDto<string> {
  pinyin?: string;
  meridian?: string;
}
export interface ClinicalTaskClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  clinicalTasks?: ClinicalTaskClinicalDataItemDto[];
  deletedItems?: ClinicalTaskClinicalDataItemDto[];
}

export interface ClinicalTaskClinicalDataItemDto
  extends ClinicalDataElementBaseDto {
  id?: string;
  patientId: string;
  taskType?: string;
  dueDate?: string;
  dueInVisits?: number;
  remainingVisits?: number;
  priority?: string;
  comment?: string;
  lockedBy?: string;
  isCompleted: boolean;
  reasonForDelete?: string;
  deletedComment?: string;
  completionNotes?: string;
  isSystemGenerated: boolean;
  claimNumber?: string;
  businessRole?: string;
  secGroupId?: string;
  completedBy?: string;
  completedDate?: string;
}

export interface ClinicalTaskDto {
  id: string;
  patientId: string;
  taskType: string;
  dueDate?: string;
  dueInVisits?: number;
  remainingVisits?: number;
  priority: string;
  comment?: string;
  lockedBy?: string;
  isCompleted: boolean;
  isDeleted: boolean;
  deletedComment?: string;
  completionNotes?: string;
  claimNumber?: string;
  isSystemGenerated: boolean;
  eTag: string;
  changeLog?: ChangeLogDto;
  businessRole?: string;
  secGroupId?: string;
  completedBy?: string;
  completedDate?: string;
}

export interface ClinicalActivityClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  clinicalActivities?: ClinicalActivityClinicalDataItemDto[];
  deletedItems?: ClinicalActivityClinicalDataItemDto[];
}

export interface ClinicalActivityClinicalDataItemDto
  extends ClinicalDataElementBaseDto {
  id: string;
  patientId: string;
  activityType: string;
  descriptionId: string;
  activityStatus?: string;
  dueDate?: string;
  freeText?: string;
  dueInVisits?: number;
  remainingVisits?: number;
  activityPriority?: string;
  comment?: string;
  lockedBy?: string;
  deletedComment?: string;
  completionNotes?: string;
  isSystemGenerated: boolean;
  businessRole?: string;
  secGroupId?: string;
  completedBy?: string;
  completedDate?: string;
  metadata?: ClinicalActivityMetadataItem[];
  reasonForDelete?: string;
  userId: string;
}

export interface ClinicalActivityDto {
  id: string;
  patientId: string;
  activityType: string;
  descriptionId: string;
  freeText?: string;
  activityStatus?: string;
  dueDate?: string;
  dueInVisits?: number;
  remainingVisits?: number;
  activityPriority?: string;
  comment?: string;
  lockedBy?: string;
  isDeleted: boolean;
  deletedComment?: string;
  completionNotes?: string;
  isSystemGenerated: boolean;
  eTag: string;
  changeLog?: ChangeLogDto;
  businessRole?: string;
  secGroupId?: string;
  completedBy?: string;
  completedDate?: string;
  metadata?: ClinicalActivityMetadataItem[];
  reasonForDelete?: string;
  userId: string;
}

export interface ClinicalActivityMetadataItem {
  key: string;
  value: any;
}

export interface GetClinicalTasksDto {
  patientName?: string;
  createdByIds?: string[];
  statuses?: string[];
  descriptions?: string[];
  dueVisits?: string[];
  priorities?: string[];
  startDateTime?: string;
  endDateTime?: string;
  patientId?: string;
  excludeConfidential?: boolean;
}

export interface GetClinicalActivitiesDto {
  patientName?: string;
  createdByIds?: string[];
  statuses?: string[];
  descriptions?: string[];
  clinicalActivityTypes?: string[];
  dueVisits?: string[];
  priorities?: string[];
  startDateTime?: string;
  endDateTime?: string;
  patientId?: string;
}

export interface ExaminationImageDto {
  id?: string;
  type: string;
  content: string | undefined;
}

export interface PhysioInjuryAreaClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  images: ExaminationImageDto[];
}

export interface PhysioMedicalHistoryClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  generalHealth?: string;
  medications?: string;
  physioMedicalHistory?: string;
  reactions?: string;
  redFlags?: string[];
  redFlagsAllClear?: boolean;
  specialQuestions?: string[];
  specialQuestionsAllClear?: boolean;
  psychosocialFlags?: string[];
  psychosocialFlagsAllClear?: boolean;
  vBIs?: string[];
  vBIsAllClear?: boolean;
  neuroSigns?: string;
  precautions?: string;
}

export interface PostureOrObservationsDto extends ClinicalDataDocumentBaseDto {
  postureOrObservations?: string;
}

export interface OtherMovementsDto extends ClinicalDataDocumentBaseDto {
  otherMovements?: string;
}
export interface CurrentInjuryClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  dateOfInjury?: string;
  mechanismOfInjury?: string;
  currentHistory?: string;
  investigations?: string[];
  investigationComment?: string;
  progression?: string;
  swelling?: string;
  stage?: string;
  severity?: string;
  irritability?: string;
  natures?: string[];
  natureComment?: string;
  dailyPattern?: string;
  sleepPattern?: string;
  aggravatingFactors?: string[];
  aggravatingFactorsComment?: string;
  easingFactors?: string[];
  easingFactorsComment?: string;
  onset?: string[];
  duration?: number;
  durationPeriod?: string;
  frequencyOccurrences?: string[];
  frequencyPeriod?: string;
  radiation?: string;
  associatedSymptoms?: string;
  concomitantFactors?: string;
  impacts?: string[];
  attributes?: string[];
  attributeComment?: string;
  appliedTreatments?: string[];
  appliedTreatmentComment?: string;
}

export interface AnalysisAndPlanClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  rxAnalysis?: string;
  analysis?: string;
  plan?: string;
}

export interface CustomClinicalToolDto {
  id?: string;
  contextId: string;
  result: string;
}

export interface CustomClinicalToolClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  tools: CustomClinicalToolDto[];
}

export interface CustomClinicalToolContextDto
  extends ResponseContextClinicalDataItemDto {
  name: string;
}

export interface CustomClinicalToolContextClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  contexts: CustomClinicalToolContextDto[];
}

export interface TreatmentAndEducationClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  analysisOfCurrentTreatment?: string;
  analysisOfCurrentTreatmentNote?: string;
  treatments?: TreatmentDataItemDto[];
  primaryMeridians?: MeridianDataItemDto[];
  extraordinaryMeridians?: MeridianDataItemDto[];
  otherTreatments?: string;
  educationOptions?: string[];
  educationComment?: string;
  otherComment?: string;
  treatmentsCleared?: boolean;
}

export interface TreatmentDataItemDto {
  treatment: string;
  comment?: string;
}

export interface MeridianDataItemDto {
  emridian?: string;
  acupoints?: string[];
  comment?: string;
}

export interface InjuryAreaClinicalDataDto extends ClinicalDataDocumentBaseDto {
  assessments?: InjuryAreaAssessment[];
}

export interface InjuryAreaImageDto {
  type: string;
  content: string;
}

export interface InjuryAreaImageItem extends InjuryAreaImageDto {
  injuryArea?: string;
}

export interface InjuryAreaAssessment {
  id?: string;
  injuryArea?: InjuryArea;
  injuryAreaImages?: InjuryAreaImageDto[];
  injuryAreaMotionAssessments?: InjuryAreaMotionAssessment[];
}

export interface UpdateImageRequest {
  injuryAreaData: InjuryAreaClinicalDataDto;
  injuryImageType: string;
  imageContent: string;
  injuryArea: InjuryArea;
}

export interface InjuryAreaMotionAssessment {
  id?: string;
  injuryAreaMotionTypeGroup?: string;
  side?: string;
  motionAssessments?: MotionAssessment[];
  palpation?: string;
  specialTests?: string;
  strength?: string;
}

export interface MotionAssessment {
  id?: string;
  motionType?: string;
  active?: number;
  passive?: number;
}
export interface GoalDataItemDto {
  goal?: string;
  startDate?: string;
  endDate?: string;
  isAchieved?: boolean;
  achievedDate?: string;
  notAchievedReason?: string;
}
export interface EpisodeOfCareDto {
  id: string;
  patientId: string;
  eTag: string;
  changeLog?: ChangeLogDto;
  diagnoses?: DiagnosisDataItemDto[];
  discharged?: boolean;
  referralNumber?: string;
  referralProvider?: string;
  referralDate?: string;
  injuryDate?: string;
  isReferral?: boolean;
}

export enum InjuryImageType {
  SpinalCordFront = "SCF",
  SpinalCordSide = "SCS",
  SpinalCordBack = "SCB",
  HandsWrists = "HW",

  MaleFrontKnee = "MFK",
  MaleBackKnee = "MBK",
  MaleLeftSideKnee = "MLSK",
  MaleRightSideKnee = "MRSK",
  FemaleFrontKnee = "FFK",
  FemaleBackKnee = "FBK",
  FemaleLeftSideKnee = "FLSK",
  FemaleRightSideKnee = "FRSK",

  FootFront = "FOF",
  FootBack = "FOB",
  FootTop = "FOT",
  FootBottom = "FOTBM",
  FootInsideLeft = "FIL",
  FootInsideRight = "FIR",
  FootOutsideLeft = "FOL",
  FootOutsideRight = "FOR",

  MaleFrontHip = "MFH",
  MaleBackHip = "MBH",
  MaleLeftSideHip = "MLSH",
  MaleRightSideHip = "MRSH",
  FemaleFrontHip = "FFH",
  FemaleBackHip = "FBH",
  FemaleLeftSideHip = "FLSH",
  FemaleRightSideHip = "FRSH",

  FemaleLeftShldrSide = "FLSS",
  FemaleLeftShldrFront = "FLSF",
  FemaleLeftShldrBack = "FLSB",
  FemaleRightShldrSide = "FRSS",
  FemaleRightShldrFront = "FRSF",
  FemaleRightShldrBack = "FRSB",

  MaleLeftShldrSide = "MLSS",
  MaleLeftShldrFront = "MLSF",
  MaleLeftShldrBack = "MLSB",
  MaleRightShldrSide = "MRSS",
  MaleRightShldrFront = "MRSF",
  MaleRightShldrBack = "MRSB",

  NBLeftShldrSide = "NBLSS",
  NBLeftShldrFront = "NBLSF",
  NBLeftShldrBack = "NBLSB",
  NBRightShldrSide = "NBRSS",
  NBRightShldrFront = "NBRSF",
  NBRightShldrBack = "NBRSB",

  MaleLeftElbow = "MLE",
  MaleRightElbow = "MRE",

  FemaleLeftElbow = "FLE",
  FemaleRightElbow = "FRE",

  NBLeftElbow = "NBLE",
  NBRightElbow = "NBRE",

  DermatomesFront = "DFT",
  DermatomesBack = "DBK",
  DermatomesSpine = "DSP",
  DermatomesFace = "DFC",
  DermatomesSide = "DS",

  CentralNervousSystemCranial = "CNSC"
}

export enum ImageTypeCode {
  FullBodyFrontSide = "FBFS",
  FullBodyBackSide = "FBBS",
  FullBodyRightSide = "FBRS",
  FullBodyLeftSide = "FBLS",
  KneeFrontSide = "KNFS",
  KneeBackSide = "KNBS",
  KneeRightSide = "KNRS",
  KneeLeftSide = "KNLS",
  HandWrist = "HANW",
  HipFrontSide = "HIPFS",
  HipBackSide = "HIPBS",
  HipRightSide = "HIPRS",
  HipLeftSide = "HIPLS",
  FootFront = "FOOTFS",
  FootBack = "FOOTBS",
  FootTop = "FOOTTOP",
  FootBottom = "FOOTBOT",
  FootLeftInside = "FOOTLI",
  FootRightInside = "FOOTRI",
  FootLeftOutside = "FOOTLO",
  FootRightOutside = "FOOTRO",
  ShoulderLeftFrontSide = "SHOULLFS",
  ShoulderLeftBackSide = "SHOULLBS",
  ShoulderLeftSide = "SHOULLS",
  ShoulderRightFrontSide = "SHOULRFS",
  ShoulderRightBackSide = "SHOULRBS",
  ShoulderRightSide = "SHOULRS",
  ElbowLeftSide = "ELBLS",
  ElbowRightSide = "ELBRS",
  SPIFrontSide = "SPIFS",
  SPIBackSide = "SPIBS",
  SPISide = "SPIS",
  Abdomen = "ABD",
  DERMFront = "DFT",
  DERMBack = "DBK",
  DERMSpine = "DSP",
  DERMFace = "DFC",
  DERMSide = "DS",
  CentralNervousSystemCranial = "CNSC"
}

export interface GetDocumentUrlArgs {
  patientId: string;
  documentId: string;
  excludeContentDisposition?: boolean;
}

export interface FollowUpNotesClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  progression?: string;
  followUpNotesComment?: string;
}

export interface DiagnosesClinicalDataDto extends ClinicalDataDocumentBaseDto {
  diagnoses?: DiagnosisDataItemDto[];
  diagnosisComment?: string;
}

export interface DiagnosisDataItemDto {
  diagnosisCode?: CodedFieldClinicalDataItemDto;
  diagnosisSide?: string;
  isPrimaryDiagnosis?: boolean;
}

export interface InjuryClinicalDataDto extends ClinicalDataDocumentBaseDto {
  dateOfSurgery?: string;
}

export interface DischargeClinicalDataDto extends ClinicalDataDocumentBaseDto {
  dataItems?: DischargeDataItemDto[];
}

export interface DischargeDataItemDto extends ClinicalDataDocumentBaseDto {
  id?: string;
  businessRoleCode?: string;
  dischargeStatus?: string;
  dischargeDate?: string;
  discharger?: string;
  outcomeDetail?: DischargeOutcomeDetail;
  referralFollowUp?: DischargeReferralFollowUp;
  reverseDischargeComment?: string;
  dischargedByUserId?: string;
}

export interface DischargeOutcomeDetail {
  outcome?: string;
  detail?: string[];
  comment?: string;
}

export interface DischargeReferralFollowUp {
  followUpNotes?: string;
  followUpDueDate?: string;
}

export interface ClaimReviewClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  reviewStatus?: string;
  reviewDocumentStatus?: string;
  claimNumber?: string;
  provider?: string;
  providerType?: string;
}

export interface PatientTreatmentPlanClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  treatmentPlans?: PatientTreatmentPlanDataItemDto[];
}

export interface PatientTreatmentPlanDataItemDto
  extends ClinicalDataElementWithDeleteDto {
  id?: string;
  linkId?: string;
  treatmentPlanLinkType?: string;
  treatments?: TreatmentDataItemDto[];
  otherTreatments?: string;
  educationOptions?: string[];
  otherEducationComment?: string;
  educationComment?: string;
  plan?: string;
  goals?: GoalDataItemDto[];
  businessRoleCode?: string;
  psfsGoalsAdded?: boolean;
  treatmentPlanDiscussed?: boolean;
  warningExplained?: boolean;
  consentObtained?: boolean;
  culturalNeedsIdentified?: boolean;
  additionalDiscussions?: string;
}

export interface DocumentOperationsClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  createdDocuments?: DocumentOperationClinicalDataItem[];
  updatedDocuments?: DocumentOperationClinicalDataItem[];
  deletedDocuments?: DocumentOperationClinicalDataItem[];
}

export interface DocumentOperationClinicalDataItem
  extends ClinicalDataElementBaseDto {
  id?: string;
  documentId: string;
  patientId: string;
  to?: string;
  from?: string;
  type: CorrespondenceType | ClaimAdjustmentDocumentTypes;
  direction: CorrespondenceDirection;
  content?: string;
  status: CorrespondenceStatus;
  store?: string;
  metadata: DocumentMetadataItem[];
  properties?: DocumentPropertiesDto;
  showOnTimeline?: boolean;
}

export interface ImagingRequestsDataDto extends ClinicalDataDocumentBaseDto {
  imagingRequests?: ImagingRequestDataItemDto[];
}

export enum Outcome {
  AttemptedContact = "ATTEMPTEDCONTACT",
  Informed = "INFORMED",
  Given = "GIVEN",
  Booked = "BOOKED",
  None = "NONE"
}

export enum RequestStatus {
  Returned = "Returned",
  Requested = "Requested",
  Cancelled = "Cancelled",
  urgentRequest = "Urgent request"
}

export interface ImagingRequestDataItemDto extends ClinicalDataElementBaseDto {
  id?: string;
  requestDate: string;
  urgent: boolean;
  patientId: string;
  to: string;
  from: string;
  location: string;
  modalities: ImagingModalityDataItemDto[];
  additionalServiceInformation?: string;
  clinicalHistory?: string;
  provisionalDiagnoses: DiagnosisDataItemDto[];
  currentMedications?: string;
  documentId?: string;
  status?: RequestStatus;
  secGroupId?: string;
  episodeOfCareId?: string;
  pregnant?: boolean;
}

export interface ImagingModalityDataItemDto extends ClinicalDataElementBaseDto {
  id?: string;
  modality?: string;
  regions?: ImagingRegionDataItemDto[];
}

export interface ImagingRegionDataItemDto extends ClinicalDataElementBaseDto {
  id?: string;
  region?: string;
  side?: RefDataDto<string>[];
  customViews: string[];
  weightBearing?: boolean;
  other?: string;
}

export interface ImagingReportsDataDto extends ClinicalDataDocumentBaseDto {
  imagingReports?: ImagingReportsDataItemDto[];
}

export interface ImagingReportsDataItemDto extends ClinicalDataElementBaseDto {
  id?: string;
  documentId?: string;
  xrayParameters?: XrayParameters;
  reportText?: string;
}

export interface XrayParameters extends ClinicalDataElementBaseDto {
  region?: string;
  side?: string;
  views: ImagingReportView[];
}

export interface ImagingReportView extends ClinicalDataElementBaseDto {
  view?: string;
  parameters: ImagingReportViewParameter[];
}

export interface ImagingReportViewParameter extends ClinicalDataElementBaseDto {
  code?: string;
  value?: string;
}

export interface ClinicalTasksUnlockArgs {
  taskIds: string[];
}

export interface ClinicalActivityUnlockArgs {
  activityIds: string[];
}

export interface SendClinicalDocumentArgs {
  patientId: string;
  documentId: string;
  email: string;
}

export interface DocumentMoveArgsDto {
  sourcePatientId: string;
  eTag: string;
  destinationPatientId: string;
}

export interface DocumentDeleteArgsDto {
  patientId: string;
  eTag: string;
  reasonForDelete?: string;
  reasonForDeleteComment?: string;
}

export interface DocumentStagingInfo {
  stagingUrl: string;
  stagingId: string;
}

export enum SideOfBody {
  Both = "B",
  Left = "L",
  Neither = "N",
  Right = "R"
}

export interface SleepClinicalDataDto extends ClinicalDataDocumentBaseDto {
  sleepQuality?: string;
  sleepPosition?: string;
  numOfHours?: number;
  sleepTime?: string;
  wakeSameTime?: BoolObservation;
  wakeTime?: string;
  weekendDiffer?: BoolObservation;
  weekendDifferComment?: string;
  snore?: string;
  electronicDevices?: BoolObservation;
  environment?: string;
  disrupted?: BoolObservation;
  comfortable?: BoolObservation;
  comfortableComment?: string;
  troubleFallingAsleep?: BoolObservation;
  stopsSleep?: string;
  whatHelpsSleep?: string;
  sleepDisturbances?: BoolObservation;
  kindOfDisturbances?: string;
  wakeDuringNight?: BoolObservation;
  timesWakeDuringNight?: number;
  troubleGoingBackToSleep?: BoolObservation;
  wakeEarly?: BoolObservation;
  wakeEarlyAtSameTime?: BoolObservation;
  wakeEarlyTime?: string;
  restedWhenAwake?: BoolObservation;
  daytimeSleepiness?: BoolObservation;
  concentrationAndMemory?: BoolObservation;
}

export interface SleepConfirmedClinicalDataDto
  extends ClinicalDataConfirmedorUpdatedClinicalDataDto {}

export const YES = "Y";
export const NO = "N";

export interface GeneralExaminationClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  skinDiscolouration?: string;
  skinCyanosisPattern?: string;
  pulseRhythm?: string;
  pulseCharacteristic?: string;
  pulses?: PulseDataItemDto[];
  bloodPressures?: BloodPressureDataItemDto[];
  temperature?: number;
  temperatureMethod?: string;
  respiratoryRate?: number;
  o2SaturationRate?: number;
  weight?: number;
  height?: number;
  hydration?: string;
  hydrationRate?: number;
  bGLLevel?: number;
  bGLFasting?: string;
  bodyHabitus?: string;
  neck?: number;
  hip?: number;
  waist?: number;
  nails?: NailDataItemDto[];
  hands?: HandDataItemDto[];
  comment?: string;
}

export interface NailDataItemDto extends ClinicalDataElementBaseDto {
  nail: string;
}

export interface HandDataItemDto extends ClinicalDataElementBaseDto {
  hand: string;
}

export interface BloodPressureDataItemDto extends ClinicalDataElementBaseDto {
  systolic?: number;
  diastolic?: number;
  arm?: string;
  bPPosition?: string;
  timeStamp?: string;
}

export interface PulseDataItemDto extends ClinicalDataElementBaseDto {
  heartRate?: number;
  position?: string;
  method?: string;
  timeStamp?: string;
}

export interface GeneralExamConfirmedClinicalDataDto
  extends ClinicalDataConfirmedorUpdatedClinicalDataDto {}

export interface HofPCConfirmedClinicalDataDto
  extends ClinicalDataConfirmedorUpdatedClinicalDataDto {}

export interface BodyAreaExaminationConfirmedClinicalDataDto
  extends ClinicalDataConfirmedorUpdatedClinicalDataDto {}

export interface BodyAreaExaminationClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  bodyAreasData?: BodyAreaClinicalDataItemDto[];
}
export interface BodyAreaClinicalDataItemDto
  extends ClinicalDataElementBaseDto {
  bodyArea: string;
  images: ExaminationImageDto[];
  comments?: ExaminationCommentDto[];
  specialTestResponse?: SpecialTestResponseDto;
  strengthTestResponse?: SpecialTestResponseDto;
  injuryAreaMotionAssessments?: InjuryAreaMotionAssessment[];
}

export interface ExaminationCommentDto {
  title?: string;
  comment?: string;
}

export interface MyotomeDataItemDto {
  nerve: string;
  strength?: number;
  side: string;
}

export interface ReflexDataItemDto {
  nerve: string;
  strength?: number;
  side?: string;
}

export interface DermatomeDataItemDto {
  nerve: string;
  side?: string;
  estimation?: string;
  testType: string;
  comment?: string;
}

export enum BodyArea {
  Body = "FB",
  Spine = "SPI",
  HandAndWrist = "HAN",
  Knee = "KNE",
  AnkleFoot = "FOO",
  Hip = "HIP",
  Shoulder = "SHO",
  LSHO = "LSHO",
  RSHO = "RSHO",
  Elbow = "ELB",
  LElbow = "LELB",
  RElbow = "RELB",
  Abdomen = "ABD",
  DermatomesAndMyotomes = "DAM",
  LHAN = "LHAN",
  RHAN = "RHAN",
  LFOO = "LFOO",
  RFOO = "RFOO",
  CentralNervousSystemCranial = "CNSC"
}

export enum CentralNervousTest {
  CRANER = "CRANER"
}

export enum StrengthSpecialTest {
  FB = "FBSTR",
  SPI = "SPISTR",
  HAN = "HANSTR",
  KNE = "KNESTR",
  FOO = "FOOTSTR",
  HIP = "HIPSTR",
  SHO = "SHOSTR",
  ELB = "ELBSTR",
  ABD = "ABDSTR",
  DAM = "DAMSTR"
}

export enum SystemReviewLabel {
  SYS = "Systemic",
  NEURO = "Neurological",
  ENT = "Ear / Nose / Throat",
  CARDIO = "Cardiology",
  GASTRO = "Gastrointestinal",
  GENURN = "Genitourinary",
  GYN = "Gynaecological",
  MUS = "Musculoskeletal",
  HAEM = "Haematological",
  NAD = "No abnormalities detected (NAD)"
}

export interface AmendmentClinicalDataDto extends ClinicalDataDocumentBaseDto {
  amendments?: AmendmentDataItem[];
}

export interface AmendmentDataItem extends ClinicalDataElementBaseDto {
  encounterId: string;
}

export interface ClinicalFlagsClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  redFlags?: string[];
  redFlagsAllClear?: boolean;
  yellowFlags?: string[];
  yellowFlagsAllClear?: boolean;
}

export interface ClinicalFlagsConfirmedClinicalDataDto
  extends ClinicalDataConfirmedorUpdatedClinicalDataDto {}

export interface SystemsReviewClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  systemicFlags?: string[];
  systemicFlagsAllClear?: boolean;
  neuroFlags?: string[];
  neuroFlagsAllClear?: boolean;
  eNTFlags?: string[];
  eNTFlagsAllClear?: boolean;
  cardioFlags?: string[];
  cardioFlagsAllClear?: boolean;
  gastroFlags?: string[];
  gastroFlagsAllClear?: boolean;
  urinaryFlags?: string[];
  urinaryFlagsAllClear?: boolean;
  musculoFlags?: string[];
  musculoFlagsAllClear?: boolean;
  haematoFlags?: string[];
  haematoFlagsAllClear?: boolean;
  gynae?: GynaeFieldDataDto;
}

export interface GynaeFieldDataDto {
  flags?: string[];
  flagsAllClear?: boolean;

  couldBePregnant?: boolean;
  lastMenstrualPeriodDate?: string;
  pregnancyCount?: number;
  contraceptionType?: string;
  sleepDisturbances?: boolean;
  hotFlushes?: boolean;
  moodDisturbances?: boolean;
  tiredness?: boolean;
  hRT?: boolean;
  lastBreastScreenDateMonth?: number;
  lastBreastScreenDateYear?: number;
  lastCervicalScreenDateMonth?: number;
  lastCervicalScreenDateYear?: number;
  comments?: string;
}

export interface SystemsReviewConfirmedClinicalDataDto
  extends ClinicalDataConfirmedorUpdatedClinicalDataDto {}

export interface DermatomesAndMyotomesClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  images: ExaminationImageDto[];
  comments?: ExaminationCommentDto[];
  myotomes?: MyotomeDataItemDto[];
  reflexes?: ReflexDataItemDto[];
  dermatomes?: DermatomeDataItemDto[];
}

export interface FamilyHistoryClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  unknownFamilyHistory?: boolean;
  noSignificantFamilyHistory?: boolean;
  generalFamilyHistoryComment?: string;
  motherAliveStatus?: FamilyAliveStatus;
  motherAgeAtDeath?: number;
  motherDeceasedReason?: CodedFieldClinicalDataItemDto;
  fatherAliveStatus?: FamilyAliveStatus;
  fatherAgeAtDeath?: number;
  fatherDeceasedReason?: CodedFieldClinicalDataItemDto;
  otherComments?: string;
  significantFamilyHistories?: SignificantFamilyHistoryDataItemDto[];
  additionalFamilyHistories?: SignificantFamilyHistoryDataItemDto[];
}

export interface FamilyHistoryConfirmedClinicalDataDto
  extends ClinicalDataConfirmedorUpdatedClinicalDataDto {}

export interface CentralNervousSystemClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  cranialNerveTestResponse?: SpecialTestResponseDto;
  comments?: ExaminationCommentDto[];
  images: ExaminationImageDto[];
}

export interface CentralNervousSystemConfirmedClinicalDataDto
  extends ClinicalDataConfirmedorUpdatedClinicalDataDto {}

export enum FamilyAliveStatus {
  YES = "YES",
  NO = "NO",
  UNKNOWN = "UNKNOWN"
}

export enum FamilyMember {
  FATHER = "father",
  MOTHER = "mother"
}

export enum MotionType {
  active = "active",
  passive = "passive"
}

export enum InjuryAreaGroups {
  Cervical = "CER",
  Thoracic = "THO",
  Lumbosacral = "LUM",
  Spine = "SPI",
  Hand = "HAN",
  Thumb = "THU",
  Finger = "FIN",
  Hip = "HIP"
}

export enum InjuryAreaSides {
  Left = "L",
  Right = "R"
}

export interface DermatomesAndMyotomesConfirmedClinicalDataDto
  extends ClinicalDataConfirmedorUpdatedClinicalDataDto {}

export const FULLBODY_IMAGES = "FBI";
export const sotapSections: string[] = [
  ClinicalNoteSections.Subjective,
  ClinicalNoteSections.Objective,
  ClinicalNoteSections.Treatment,
  ClinicalNoteSections.Analysis,
  ClinicalNoteSections.Plan
];

export const soapSections: string[] = [
  ClinicalNoteSections.Subjective,
  ClinicalNoteSections.Objective,
  ClinicalNoteSections.Assessment,
  ClinicalNoteSections.Plan
];

export interface EocInitialConsultDateAndVisitCount {
  episodeOfCareId?: string;
  initialConsultDate?: DateTime;
  totalVisitCount?: number;
}

export interface InteractionsFilter {
  patientId?: string;
  search?: string;
  dateFrom?: string;
  dateTo?: string;
  sourceId?: string;
  types?: string[];
}

export interface MeasurementsFilter {
  patientId?: string;
  types?: string[];
  dateFrom?: Date;
  dateTo?: Date;
  encounterId?: string;
  contextId?: string;
  id?: string;
}

export enum PSFSReason {
  Baseline = "BASE",
  ApprovalForFutherTreatment = "AFFT",
  Continuation = "CONT",
  Discharge = "DISC"
}

export enum PSFSCloseReason {
  NoFurtherTreatmentProviderAdvised = "NFTPRO",
  NoFurtherTreatmentPatientAdvised = "NDFPAT",
  PatientHasNotReturned = "PNR",
  Discharged = "DISC",
  Other = "OTH"
}

export enum Medication {
  Title = "Medications",
  Current = "Current",
  Scripts = "Scripts",
  Previous = "Previous"
}

export enum ClinicalNotification {
  AddNotification = "Add notification",
  Add = "Add",
  Edit = "Edit",
  Delete = "Delete",
  DeleteSingle = "Delete following notification:",
  DeleteMultiple = "Delete following notifications:",
  DeleteModalTitleWithSingle = "Delete notification?",
  DeleteModalTitleWithMultiple = "Delete notifications?",
  NotificationListHeading = "Clinical notifications",
  MarkAsCompleted = "Mark as completed"
}

export interface MedicationsAndSupplementsClinicalDataDto
  extends ClinicalDataDocumentBaseDto {
  medicationsAndSupplements: MedicationsAndSupplementsClinicalDataItemDto[];
}

export interface MedicationsAndSupplementsClinicalDataItemDto
  extends ClinicalDataElementBaseDto {
  product?: string;
  description?: string;
}

export interface MedicationsAndSupplementsConfirmedClinicalDataDto
  extends ClinicalDataConfirmedorUpdatedClinicalDataDto {}

export interface ClinicalActivitiesPreferenceDto {
  id: string;
  allowReminderFreeText?: boolean;
  eTag: string;
  changeLog?: ChangeLogDto;
}

export interface ActivityDescriptionDto {
  id: string;
  reasonText: string;
  isSupplied: boolean;
  interval?: number;
  frequency?: string;
  clinicallySignificant?: boolean;
  tenant?: string;
  isInactive?: boolean;
  activityType: string;
  convertedDescriptionCode?: string;
  eTag: string;
  changeLog?: ChangeLogDto;
}

export interface ActivityDescriptionArgs {
  activityType?: string[];
  isInactive?: boolean;
}

export interface PatchClinicalActivitiesPreferenceDto
  extends Patch<Omit<ClinicalActivitiesPreferenceDto, "changeLog">> {
  id: string;
  eTag: string;
}

export interface AdministrationReminderContactRecordDto {
  contactType: string;
  timeStamp: string;
  status: string;
}

export interface AdministrationReminderDto {
  id: string;
  reminderStatus: string;
  clinicalActivityId: string;
  contactRecords: AdministrationReminderContactRecordDto[];
}

export interface AdministrationReminderCreateBatchArgs {
  activities: ClinicalActivityDto[];
  orgUnitId: string;
}

export interface AdministrationReminderGetByArgs {
  activityIds: string[];
}
